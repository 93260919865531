<template>
    <page title="Cidades">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
<!--        <div slot="menu" class="d-flex justify-space-between align-center">
            <v-menu offset-y >
                <template v-slot:activator="{ on }">
                    <v-btn outlined style="border: solid thin rgba(0, 0, 0, 0.12)" v-on="on">
                        <v-icon left>mdi-cog</v-icon>
                        <span>Ações</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="downloadResultadoXls" :disabled="downloadingResumo">
                        <v-list-item-icon>
                            <v-icon>mdi-database-export</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Exportar resultado</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>-->
        <filter-list-fragment v-model="currentFilter" @input="onFilterChanged" />

        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>

        <div class="text-center" v-if="cidades">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>
        <v-card class="mt-3" >

            <v-list class="py-0" v-if="cidades && cidades.length > 0">
                <template v-for="(cidade, index) in cidades">
                    <v-list-item  :key="index" :to="{ name: 'cidade_view', params: { id: parseInt(cidade.id) }}" >
                        <v-list-item-content>
                            <v-list-item-title v-html="highlight(cidade.nome, currentFilter.searchValue)"></v-list-item-title>
                            <v-list-item-subtitle>
                                <template v-if="cidade.totalAgencias > 0">
                                    <v-chip x-small label color="teal lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                        <span>{{cidade.totalAgencias}} Agências</span>
                                    </v-chip>
                                </template>
                                <v-chip v-else x-small label color="grey lighten-2" text-color="black" class="mr-1 font-weight-regular px-2">
                                    <span>Nenhuma agência</span>
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                <span>{{cidade.estado.sigla}}</span>
                            </v-list-item-action-text>
<!--                            <v-list-item-action-text>
                                <v-chip v-if="agencia.fechadaEm" x-small label color="red" dark class="mr-1 font-weight-bold px-2">
                                    <span>FECHADA</span>
                                </v-chip>
                                <v-chip v-if="agencia.centralNf" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                    <span>Centralizadora</span>
                                </v-chip>
                            </v-list-item-action-text>-->
                        </v-list-item-action>

                    </v-list-item>
                    <v-divider ></v-divider>
                </template>
            </v-list>
            <div v-else-if="loading === false && (cidades === null || cidades.length === 0)" class="text-center pa-6">
                <h1 class="display-3 mb-4">Ops!</h1>
                <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
            </div>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading"
                               absolute top color="secondary" />
        </v-card>

        <div class="text-center mt-3" v-if="cidades">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>
    </page>
</template>

<script>
    // @ts-ignore
    import page from '../../../components/Page';
    import localforage from "localforage";
    import {IdData} from "@/models/filter/IdData";
    import {BooleanData} from "@/models/filter/BooleanData";
    import {DateData} from "@/models/filter/DateData";
    import {SortData} from "@/models/filter/SortData";
    import gql from "graphql-tag";
    // @ts-ignore
    import FilterListFragment from "./fragments/filter/FilterListFragment";
    import {EstadoData} from "@/models/filter/EstadoData";

    export default {
        name: "Index",
        components: {
            page,
            FilterListFragment
        },
        data() {
            return {
                offset: 20,
                page: 1,
                totalCount: 0,
                cidades: [],
                currentFilter: {},
                loading: true,
            }
        },
        watch: {
            $route(to, from) {
                if(to.query.page === undefined){
                    this.$router.push({query:{page: '1'}});
                    return;
                }
                this.page = parseInt(to.query.page);
                if(to.query.page === from.query.page){
                    return
                }

                this.getList(this.currentFilter)
            }
        },
        methods: {
            pageChanged(value){
                this.$router.push({query:{page:this.page}});
            },
            getList(filter) {
                this.loading = true;

                this.$apollo.query({
                    query: gql`query ($filter: SearchFilterInput!, $page: Int, $offset: Int){
                        searchCidades(filter: $filter, page: $page, offset: $offset){
                            totalCount
                            cidades:itens{
                                id
                                nome
                                codigo
                                estado{
                                    sigla
                                }
                                totalAgencias
                            }
                        }
                    }`,
                    variables: {
                        filter: this.formatFilterToRequest(filter),
                        page: this.page - 1,
                        offset: this.offset
                    },
                }).then((result) => {
                    this.cidades = result.data.searchCidades.cidades;
                    this.totalCount = result.data.searchCidades.totalCount;
                    this.loading = false;
                });
            },
            onFilterChanged(filter) {
                this.currentFilter = filter;
                this.saveFilter(filter);
                if(this.$route.query.page == 1){
                    this.getList(this.currentFilter);
                    return
                }
                this.$router.replace({query:{page:1}});
            },
            formatFilterToRequest(filter) {
                const parsedFilter = this._.cloneDeep(filter);

                if (!parsedFilter.searchValue) {
                    parsedFilter.searchCriteria = null;
                }

                if (filter.itens) {
                    parsedFilter.itens = filter.itens.map((filterItem) => {
                        const filterItemMapped = {
                            name: filterItem.name,
                        };

                        switch (filterItem.type) {
                            case 'estado':
                                filterItemMapped.estadoData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.estadoData.nome;
                                delete filterItemMapped.estadoData.sigla;
                                break;
                            case 'boolean':
                                filterItemMapped.booleanData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.booleanData.trueLabel;
                                delete filterItemMapped.booleanData.falseLabel;
                                delete filterItemMapped.booleanData.toLabel;

                                break;
                            case 'sort':
                                filterItemMapped.sortData = this._.cloneDeep(filterItem.data);
                                filterItemMapped.sortData.fieldName = filterItemMapped.sortData.field.name;
                                delete filterItemMapped.sortData.field;
                                delete filterItemMapped.sortData.toLabel;
                                break;
                            case 'date':
                                filterItemMapped.dateData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.dateData.rangeType;
                                delete filterItemMapped.dateData.toLabel;
                                break
                            case 'tipo':
                                filterItemMapped.idData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.idData.nome;
                                delete filterItemMapped.idData.toLabel;
                                break;
                        }

                        return filterItemMapped;
                    });
                }

                return parsedFilter;
            },
            saveFilter(filter) {
                const filterParsed = this._.cloneDeep(filter);
                filterParsed.searchCriteria = null;
                filterParsed.searchValue = null;

                localforage.setItem('cidades_filter', filterParsed);
            },
            downloadResultadoXls(){
                this.downloadingResumo = true;
                this.$axios.post(
                    '/agencias/resultado/xls',
                    this.formatFilterToRequest(this.currentFilter),
                    {responseType: 'blob'})
                    .then((response) => {
                        const fileLink = document.createElement('a');
                        fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                        const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                        fileLink.setAttribute('download', fileName);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                        this.downloadingResumo = false;
                    }).catch(() => {
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });
                    this.downloadingResumo = false;

                });
            },
            async loadFilter() {
                const savedFilter = await localforage.getItem('cidades_filter');
                if (savedFilter) {
                    savedFilter.itens.map((filterItem) => {
                        switch (filterItem.type) {
                            case 'estado':
                                filterItem.data = new EstadoData(filterItem.data.id, filterItem.data.nome, filterItem.data.sigla);
                                break;
                            case 'tipo':
                                filterItem.data = new IdData(filterItem.data.id, filterItem.data.nome);
                                break;
                            case 'boolean':
                                filterItem.data = new BooleanData(
                                    filterItem.data.status, filterItem.data.trueLabel, filterItem.data.falseLabel);
                                break;
                            case 'date':
                                filterItem.data = new DateData(filterItem.data.before, filterItem.data.after, filterItem.data.rangeType);
                                break;
                            case 'sort':
                                filterItem.data = new SortData(
                                    filterItem.data.field.label, filterItem.data.field.name, filterItem.data.ascending);
                                break;
                        }
                        return filterItem;
                    });
                }
                return savedFilter;
            },
            refreshList(){
                this.loading = true;
                this.getList(this.currentFilter);
            },
            highlight: function(words, query){
                if(query === ''){
                    return words;
                }
                return words.toString().replace(new RegExp(query, "gi"), matchedTxt => {
                    return '<span class="highlight">' + matchedTxt + '</span>';
                });
            },
        },
        mounted() {
            this.loading = true;
            document.title = "Cidades - JF Assessoria Rural"
            this.loadFilter().then((filter) => {
                if(!this.$route.query.page){
                    this.$router.replace({query:{page:this.page}});
                    return;
                }else{
                    this.page = parseInt(this.$route.query.page);
                }
                if (filter) {
                    // TODO Montar o componente do filtro aqui
                    this.currentFilter = filter;
                    this.getList(this.currentFilter);
                } else {
                    this.getList(this.currentFilter);
                }
            })
        },
    }
</script>

<style>
    span.highlight {
        background-color: yellow;
    }
</style>
<style scoped>
</style>