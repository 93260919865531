<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-card-title>
                <span class="headline">Novo Filtro</span>
            </v-card-title>
            <v-card-text>
                <v-select :items="items" v-model="dialogSelectedFilter" item-text="title" return-object
                          label="Filtro">
                </v-select>
                <div v-if="dialogSelectedFilter">
                    <estado-form v-if="dialogSelectedFilter.type === 'estado'"
                                 v-model="dialogSelectedFilter"
                                 @input="onFilterChanged"
                                 @canceled="onFilterCanceled"/>
                    <boolean-form v-if="dialogSelectedFilter.type === 'boolean'"
                                  v-model="dialogSelectedFilter"
                                  @input="onFilterChanged"
                                  @canceled="onFilterCanceled"></boolean-form>
                    <sort-form v-if="dialogSelectedFilter.type ==='sort'"
                               v-model="dialogSelectedFilter"
                               @input="onFilterChanged"
                               @canceled="onFilterCanceled"></sort-form>
                </div>
                <div v-if="dialogSelectedFilter === null" class="text-right">
                    <v-btn class="mr-2" text @click="dialog = false">Cancelar</v-btn>
                    <v-btn color="primary" text disabled>Aplicar</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    // @ts-ignore
    import BooleanForm from './BooleanForm';
    // @ts-ignore
    import SortForm from './SortForm';
    // @ts-ignore
    import EstadoForm from "./EstadoForm";

    export default {
        name: 'NewFilterDialog',
        components: {
            EstadoForm,
            SortForm,
            BooleanForm,
        },
        props: {
            items: null,
        },
        data() {
            return {
                dialog: false,
                dialogSelectedFilter: null,
            };
        },
        methods: {
            openDialog() {
                this.dialog = true;
                this.dialogSelectedFilter = null;
            },
            closeDialog() {
                this.dialog = false;
            },
            onFilterChanged(filter) {
                this.$emit('input', this.dialogSelectedFilter);
                this.closeDialog();
            },
            onFilterCanceled() {
                this.closeDialog();
            },
        },
    };
</script>

<style scoped>

</style>